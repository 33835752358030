import React, { useState, useEffect } from 'react';
import {
	Container,
	Row,
	FormGroup,
	Label,
	Col,
	Button,
	Input,
	FormFeedback,
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Loading } from '../utils/utils';
import { getHttpMethods } from '../utils/httpMethods';
import { enAPI } from '../config';
import constant from '../constants/constants.json';
import { DS_APP_URL } from '../config';
// import constants from '../constants/constants.json';
import { VscCircleFilled } from "react-icons/vsc";
const EmailForm = (props) => {
	const [loading, setLoading] = useState(false);
	const [errorEmail, setError] = useState('');
	const [subMit, setSubmit] = useState(false);
	const [formValue, setFromValue] = useState({});
	const [existingEmail, setExistingEmail] = useState(false);

	useEffect(() => {
		if (subMit) {
			setLoading(true);
			setSubmit(false);
			const http = getHttpMethods();
			http
				.get(enAPI + `DS/emailverify?email=${encodeURIComponent(formValue.email)}`)
				.then((res) => {
					setLoading(false);
					if (!res.data.email) {
						props.setFormData({ ...formValue });
						props.setValidForm(true);
					} else {
						// setError('Existing');
						setExistingEmail(true);
					}
				})
				.catch((err) => {
					props.setValidForm(false);
					setError(err);
					setLoading(false);
				});
		}
	}, [subMit, props, formValue]);

	return (
		<Container style={{ padding: '1% 8%' }}>
			{loading && <Loading />}
			<div
				style={{
					width: '100%',
					color: 'black',
					fontSize: '1rem',
					marginTop: '25px',
					marginBottom: '2%',
				}}>
				<div style={{ marginTop: '8px' }}>
					<h5 style={{ fontWeight: 'bold', textAlign: 'center' }}>
						Registration Form
					</h5>
				</div>
				<br />
				<div style={{display:"flex", marginLeft:"20px"}}>
				<span style={{ marginLeft: '-17px' }}>
						<VscCircleFilled style={{fontSize:"8px"}} />
					</span>
					<p style={{ paddingLeft: '6px' }}>Registration reserved for Authorized Sony resellers that purchase Sony
					consumer electronics and/or professional products from an{' '}
					<u>Authorized Sony distributor.</u></p>
				</div>
				<div style={{display:"flex", marginLeft:"20px"}}>
				<span style={{ marginLeft: '-17px' }}>
						<VscCircleFilled style={{fontSize:"8px"}} />
					</span>
					<p  style={{ paddingLeft: '6px' }}>Authorized Sony resellers and distributors that purchase Sony consumer
					electronics and/or professional products <u>directly from Sony</u>{' '}
					should contact their Sony Account Manager for instructions on how to
					access this portal. <b>Do not register here.</b></p>
				</div>				
			</div>
			{errorEmail && (
				<div
					className='required'
					style={{ fontSize: '1rem', textAlign: 'center' }}>
					{constant['SYTEM_ERROR']}
				</div>
			)}
			{existingEmail && (		
						<div
					className='required'
					style={{ fontSize: '1rem', textAlign: 'center' }}>
					{constant['EMAIL_ALREDY_EXISTS']} Please{' '}
					<a
						href={DS_APP_URL}
						target='_blank'
						rel='noreferrer'
						style={{ color: 'blue' }}>
						Click here
					</a>{' '}
					to Sign In or Reset Password.
				</div>
			)}
			<Formik
				enableReinitialize={true}
				initialValues={{ email: '' }}
				validationSchema={Yup.object().shape({
					email: Yup.string().email('Invalid email').required('Required'),
				})}
				onSubmit={(values) => {
					setFromValue(values);
					setError('');
					setExistingEmail(false);
					setSubmit(true);
				}}>
				{({
					handleSubmit,
					values,
					handleBlur,
					touched,
					errors,
					setFieldValue,
				}) => (
					<Container className={'borderContainer'}>
						<Form onSubmit={handleSubmit}>
							<Row>
								<Col md={8}>
									<FormGroup>
										<Label style={{ fontSize: '1rem' }} for='email'>
											Email:<span className='required'>*</span>
										</Label>
										<Input
											value={values.email}
											name='email'
											type='email'
											id='email'
											aria-label='email'
											tag={Field}
											placeholder='example@abc.com'
											invalid={Boolean(touched.email && errors.email)}
											onBlur={handleBlur}
											onChange={(event) => {
												setError('');
												setExistingEmail(false);
												setFieldValue('email', event.target.value);
											}}
										/>
										<FormFeedback style={{ fontSize: '0.8rem' }}>
											{errors.email}
										</FormFeedback>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col className='text-center text-md-right'>
									<Button
										type='submit'
										disabled={Boolean(errors.email || !values.email)}>
										Next Step
									</Button>
								</Col>
							</Row>
						</Form>
					</Container>
				)}
			</Formik>
		</Container>
	);
};

export default EmailForm;
