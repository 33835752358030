import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import { ErrorMessage } from '../utils/utils';
import {
	Form,
	Row,
	Col,
	Container,
	Label,
	Input,
	FormGroup,
	Button,
	Modal,
	Alert,
	ModalBody,
} from 'reactstrap';
import * as yup from 'yup';
import { getHttpMethods } from '../utils/httpMethods';
import { enAPI, SITE_KEY_V3, SITE_KEY_V2 } from '../config';
import { Loading, generatePoolObject, loadScript } from '../utils/utils';
import constant from '../constants/constants.json';
import ReCAPTCHA from 'react-google-recaptcha';
import { DS_APP_URL } from '../config';

const DistributorDetails = (props) => {
	const [submit, setSubmit] = useState(false);
	const [finalFormData, setFinalFormData] = useState({});
	const [loading, setLoading] = useState(false);
	const [registered, setRegistered] = useState('');
	const [error, setError] = useState(false);
	const [tokenV3, setTokenV3] = useState(null);
	const [tokenV2, setTokenV2] = useState(null);
	const [v3Error, setV3Error] = useState(null);
	const [ignoreWarnings, setIgnorewarnings] = useState(false);
	const [warnings, setWarnings] = useState([]);
	const [modal, setModal] = useState(false);
	const [emailExists, setEmailExists] = useState(false);

	let TDWarning =
		'Entering your TD Synnex Account # could help avoid any delays in processing your request.';
	let ADWarning =
		'Entering your Distributor’s Account # could help avoid any delays in processing your request.';
	let BothWarning =
		'Entering your TD Synnex and Distributor’s Account # could help avoid any delays in processing your request.';

	const toggle = () => {
		setModal(!modal);
	};

	const onChange = (value) => {
		setTokenV2(value);
	};

	const ResetState = () => {
		setTokenV3(null);
		setTokenV2(null);
		setV3Error(null);
	};

	useEffect(() => {
		!tokenV3 && loadScript(SITE_KEY_V3);

		!tokenV3 &&
			window &&
			window.grecaptcha &&
			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(SITE_KEY_V3).then((res) => {
					setTokenV3(res);
				});
			});

		let poolObject = generatePoolObject(finalFormData);
		if ((tokenV3 || (tokenV2 && v3Error)) && submit) {
			let token = !tokenV2 ? tokenV3 : tokenV2;
			let version = !tokenV2 ? 'V3' : 'V2';
			setLoading(true);
			setSubmit(false);
			poolObject['siteToken'] = token;
			poolObject['captchaVersion'] = version;

			const http = getHttpMethods();
			http
				.get(enAPI + `DS/emailverify?email=${encodeURIComponent(poolObject.email)}`)
				.then((res) => {
					if (!res.data.email) {
						http
							.post(`${enAPI}DS/register`, poolObject)
							.then((res) => {
								ResetState();
								setLoading(false);
								setRegistered(res);
							})
							.catch((err) => {
								let errMsg =
									err &&
									err.response &&
									err.response.data &&
									err.response.data.message;
								if (errMsg === 'V3 succeed with Low score') setV3Error(true);
								else if (errMsg === 'Captcha Failed') setError('Failed');
								else setError(errMsg);
								setLoading(false);
							});
					} else {
						setLoading(false);
						setEmailExists(true);
					}
				})
				.catch((err) => {
					setLoading(false);
					setError('SYSTEM_ERROR');
				});
		}
	}, [submit, finalFormData, tokenV3, tokenV2, v3Error, error]);

	let captchaError = error === 'Failed';

	const WarningModal = (props) => {
		return (
			<Modal isOpen={props.modal} toggle={props.toggle}>
				<ModalBody>
					<Alert
						color='warning'
						style={{
							width: '100%',
							textAlign: 'center',
						}}>
						{props.message.map((warning) => {
							return (
								<>
									<h6>{warning}</h6>
									<br />
								</>
							);
						})}
					</Alert>
					<div style={{ float: 'right' }}>
						<Button
							color='secondary'
							onClick={() => {
								props.toggle();
							}}>
							Yes, Update
						</Button>
						&nbsp;&nbsp;
						<Button
							color='secondary'
							onClick={() => {
								props.toggle();
								setIgnorewarnings(true);
								props.submit();
							}}>
							No, Register Now
						</Button>
					</div>
				</ModalBody>
			</Modal>
		);
	};

	return (
		<React.Fragment>
			<Container
				style={{
					border: '3px solid #ddd',
					minHeight: '70vh',
				}}>
				{loading && <Loading />}
				{registered && (
					<div style={{ textAlign: 'center', padding: '15%' }}>
						<h4> {constant['SUCCESSFUL_REGISTRATION']}</h4>
						<h5 style={{ fontSize: '1rem' }}>
							{constant['RECIEVE_EMAIL']}
							<b style={{ wordWrap: 'break-word' }}>
								DONOTREPLY_DEALERSOURCE@am.sony.com
							</b>
							{constant['IF_APPROVED']}
						</h5>
					</div>
				)}
				{emailExists && (
					<h5
						className='required'
						style={{ textAlign: 'center', padding: '15%' }}>
						{constant['EMAIL_ALREDY_EXISTS']} Please{' '}
						<a
							href={DS_APP_URL}
							target='_blank'
							rel='noreferrer'
							style={{ color: 'blue' }}>
							Click here
						</a>{' '}
						to Sign In or Reset Password.
					</h5>
				)}
				{error && !captchaError && (
					<h4
						className='required'
						style={{ textAlign: 'center', padding: '15%' }}>
						{constant['SYTEM_ERROR']}
					</h4>
				)}
				{error && captchaError && (
					<h6 className='required' style={{ textAlign: 'center' }}>
						Bots are not allowed!!
					</h6>
				)}

				{(!registered || v3Error) && (!error || captchaError) && !emailExists && (
					<Formik
						initialValues={{
							professional: false,
							consumer: false,
							fromTDSynnex: false,
							TDAccount: '',
							sonyDistributor: false,
							distName: '',
							distAccount: '',
							consumerOrProCheck: false,
							companyBuyscheck: false,
							distributorNameCheck: false,
							TDAccountCheck: false,
							DistNameCheck: false,
							DistAccountCheck: false
						}}
						validationSchema={yup
							.object({
								consumer: yup.boolean(),
								professional: yup.boolean(),
								fromTDSynnex: yup.boolean(),
								sonyDistributor: yup.boolean(),
								distName: yup.string(),
							})
							.test('validateTDAccount', 'Invalid TDAccount', (obj) => {
								let regex = new RegExp("[<>{}]");
								if (!obj.TDAccount || (obj.TDAccount && !regex.test(obj.TDAccount))) return true;
								else {
									return new yup.ValidationError(
										'Invalid TDAccount',
										true,
										'TDAccountCheck'
									);
								}
							})
							.test('validateDistributorName', 'Invalid Distributor Name', (obj) => {
								let regex = new RegExp("[<>{}]");
								if (!obj.distName || (obj.distName && !regex.test(obj.distName))) return true;
								else {
									return new yup.ValidationError(
										'Invalid Distributor Name',
										true,
										'DistNameCheck'
									);
								}
							})
							.test('validateDistributorAccount', 'Invalid Distributor Account', (obj) => {
								let regex = new RegExp("[<>{}]");
								if (!obj.distAccount || (obj.distAccount && !regex.test(obj.distAccount))) return true;
								else {
									return new yup.ValidationError(
										'Invalid Distributor Account',
										true,
										'DistAccountCheck'
									);
								}
							})
							.test('atleastOneChecked', 'Please check one checkbox', (obj) => {
								if (obj.consumer || obj.professional) return true;
								else {
									return new yup.ValidationError(
										'Please select the products sold by your company',
										true,
										'consumerOrProCheck'
									);
								}
							})
							.test('atleastOneChecked', 'Please check one checkbox', (obj) => {
								if (obj.fromTDSynnex || obj.sonyDistributor) return true;
								else {
									return new yup.ValidationError(
										'Please select the appropriate choice for how your company purchases Sony products. If not listed, please contact your Sony Account Manager for further instructions instead of completing this registration.',
										true,
										'companyBuyscheck'
									);
								}
							})
							.test('atleastOneChecked', 'Please check one checkbox', (obj) => {
								if (obj.sonyDistributor ? obj.distName : true) return true;
								else {
									return new yup.ValidationError(
										'Please enter the Distributor’s Name',
										true,
										'distributorNameCheck'
									);
								}
							})}
						onSubmit={(values) => {
							let {
								TDAccount,
								fromTDSynnex,
								sonyDistributor,
								distName,
								distAccount,
							} = values;
							let distAccNoMissing =
								sonyDistributor && distName && !distAccount;
							let tdAccMissing = fromTDSynnex && !TDAccount;

							if (!ignoreWarnings && (distAccNoMissing || tdAccMissing)) {
								let warnings = [];
								distAccNoMissing && tdAccMissing
									? warnings.push(BothWarning)
									: distAccNoMissing
									? warnings.push(ADWarning)
									: warnings.push(TDWarning);
								setWarnings(warnings);
								setModal(true);
							} else {
								// console.log('entered submit', values);
								let finalObject = { ...values, ...props.formData };
								setFinalFormData(finalObject);
								setSubmit(true);
								setError('');
								setLoading(true);
							}
						}}>
						{({
							setFieldValue,
							values,
							errors,
							handleSubmit,
							touched,
							setTouched,
						}) => (
							<Form onSubmit={handleSubmit}>
								{modal && (
									<WarningModal
										modal={modal}
										toggle={toggle}
										message={warnings}
										submit={handleSubmit}
									/>
								)}
								<div
									fluid='true'
									style={{
										padding: '4% 15% 1% 15%',
										position: 'relative',
									}}>
									<Row>
										<Col md='5'>
											<h6
												style={{
													fontFamily: 'Arial, Helvetica, sans-serif',
													fontSize: '1rem',
													fontWeight: 'bold',
												}}>
												Products sold by your company:
												<span className='required'>*</span>
											</h6>
											<FormGroup>
												<FormGroup check style={{ marginLeft: '15px' }}>
													<Row>
														<Label check for={'consumer'}>
															<Field
																id={'consumer'}
																type='checkbox'
																name={'consumer'}
																component={Input}
																aria-label={'consumer'}
																value={values['consumer']}
																disabled={captchaError}
																onChange={(event) => {
																	setFieldValue(
																		'consumer',
																		event.target.checked
																	);
																}}
															/>{' '}
															<h5
																style={{
																	fontSize: '0.9rem',
																}}>
																Consumer Electronics Products
															</h5>
														</Label>
													</Row>
													<Row>
														<Label check for={'professional'}>
															<Field
																id={'professional'}
																type='checkbox'
																name={'professional'}
																component={Input}
																aria-label={'professional'}
																value={values['professional']}
																disabled={captchaError}
																onChange={(event) => {
																	setFieldValue(
																		'professional',
																		event.target.checked
																	);
																}}
															/>{' '}
															<h5
																style={{
																	fontSize: '0.9rem',
																}}>
																Professional Products
															</h5>
														</Label>
													</Row>
												</FormGroup>
												<ErrorMessage
													className='required'
													name='consumerOrProCheck'
												/>
											</FormGroup>
										</Col>

										<Col md='7'>
											<h6
												style={{
													fontFamily: 'Arial, Helvetica, sans-serif',
													fontSize: '1rem',
													fontWeight: 'bold',
												}}>
												Your company buys Sony products:
												<span className='required'>*</span>
											</h6>

											<>
												<FormGroup style={{ marginLeft: '10px' }}>
													<Col md='12'>
														<Label check for={'fromTDSynnex'}>
															<Field
																id={'fromTDSynnex'}
																type='checkbox'
																name={'fromTDSynnex'}
																component={Input}
																aria-label={'fromTDSynnex'}
																value={values['fromTDSynnex']}
																disabled={captchaError}
																onChange={(event) => {
																	setFieldValue(
																		'fromTDSynnex',
																		event.target.checked
																	);
																	!event.target.checked &&
																		setFieldValue('TDAccount', '');
																}}
															/>{' '}
															<h5 style={{ fontSize: '0.9rem' }}>
																From TD Synnex
															</h5>
														</Label>
													</Col>
													<Col md='12'>
														<Label for={'TDAccount'}>TD Account #</Label>
														<Field
															id='TDAccount'
															name='TDAccount'
															className='inputTextSize'
															component={Input}
															maxLength={15}
															aria-label='TDAccount'
															disabled={captchaError || !values.fromTDSynnex}
															value={values.TDAccount}
															onChange={(event) => {
																setFieldValue('TDAccount', event.target.value);
															}}
														/>
													   {values.TDAccount && <ErrorMessage name='TDAccountCheck' />}
													</Col>
												</FormGroup>
											</>

											<FormGroup style={{ marginLeft: '10px' }}>
												<Col md='12'>
													<Label check for='sonyDistributor'>
														<Field
															id={'sonyDistributor'}
															type='checkbox'
															name={'sonyDistributor'}
															component={Input}
															aria-label={'sonyDistributor'}
															value={values['sonyDistributor']}
															disabled={captchaError}
															onChange={(event) => {
																setFieldValue(
																	'sonyDistributor',
																	event.target.checked
																);
																if (!event.target.checked) {
																	setFieldValue('distName', '');
																	setFieldValue('distAccount', '');
																}
															}}
														/>{' '}
														<h5 style={{ fontSize: '0.9rem' }}>
															From an Authorized Sony Distributor
															<span>(Only one required)</span>
														</h5>
													</Label>
													<FormGroup>
														<Row>
															<Col md='6'>
																<Label for={'distName'}>
																	Distributor Name
																	<span className='required'>*</span>
																</Label>
																<Field
																	id='distName'
																	name='distName'
																	className='inputTextSize'
																	component={Input}
																	maxLength={50}
																	aria-label='distName'
																	disabled={
																		captchaError || !values.sonyDistributor
																	}
																	value={values.distName}
																	onChange={(event) => {
																		setFieldValue(
																			'distName',
																			event.target.value
																		);
																	}}
																/>
																{values.distName && <ErrorMessage name='DistNameCheck' />}
															</Col>
															<Col md='6'>
																<Label for={'distAccount'}>
																	Distributor Account #
																</Label>
																<Field
																	id='distAccount'
																	name='distAccount'
																	className='inputTextSize'
																	component={Input}
																	maxLength={50}
																	aria-label='distAccount'
																	disabled={
																		captchaError || !values.sonyDistributor
																	}
																	value={values.distAccount}
																	onChange={(event) => {
																		setFieldValue(
																			'distAccount',
																			event.target.value
																		);
																	}}
																/>
																{values.distAccount && <ErrorMessage name='DistAccountCheck' />}
															</Col>
															{values.sonyDistributor && (
																<ErrorMessage name={'distributorNameCheck'} />
															)}
														</Row>

														<ErrorMessage name='companyBuyscheck' />
													</FormGroup>
												</Col>
											</FormGroup>
										</Col>
									</Row>

									<Col className='text-center text-md-right'>
										<Button
											disabled={captchaError}
											type='submit'
											style={{ marginTop: '40px' }}>
											Register Now
										</Button>
									</Col>
									{v3Error && !error && (
										<ReCAPTCHA sitekey={SITE_KEY_V2} onChange={onChange} />
									)}
								</div>
							</Form>
						)}
					</Formik>
				)}
			</Container>
		</React.Fragment>
	);
};

export default DistributorDetails;
