import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
export class Autocomplete extends Component {
	static propTypes = {
		options: PropTypes.instanceOf(Array).isRequired,
	};
	state = {
		activeOption: 0,
		filteredOptions: [],
		showOptions: false,
		userInput: '',
		open: false,
	};

	onChange = (e) => {
		// this.setState({ clicked: false });
		const { options } = this.props;
		const userInput = e.currentTarget.value;
		let toLowerCaseOptions = options.map((opt) => String(opt).toLowerCase());
		const filteredOptions = options.filter(
			(optionName) =>
				String(optionName)
					.trim()
					.toLowerCase()
					.indexOf(String(userInput).trim().toLowerCase()) > -1
		);

		this.setState({
			activeOption: 0,
			filteredOptions,
			showOptions: true,
			userInput: e.currentTarget.value,
		});
		this.props.setFieldValue('companyName', e.currentTarget.value);
		if (
			!toLowerCaseOptions.includes(String(e.currentTarget.value).toLowerCase())
		) {
			this.props.setCustomCompany(e.currentTarget.value);
		} else {
			this.props.setCustomCompany('');
		}
	};

	onClick = (e) => {
		this.setState({ clicked: true });
		this.setState({
			activeOption: 0,
			filteredOptions: [],
			showOptions: false,
			userInput: e.currentTarget.innerText,
		});
		let toLowerCaseOptions = this.props.options.map((opt) =>
			String(opt).toLowerCase()
		);
		this.props.setFieldValue('companyName', e.currentTarget.innerText);
		if (
			toLowerCaseOptions.includes(
				String(e.currentTarget.innerText).toLowerCase()
			)
		) {
			this.props.setCustomCompany('');
		} else {
			this.props.setCustomCompany(e.currentTarget.innerText);
		}
	};
	onKeyDown = (e) => {
		const { activeOption, filteredOptions } = this.state;

		if (e.keyCode === 13) {
			this.setState({
				activeOption: 0,
				showOptions: false,
				userInput: filteredOptions[activeOption],
			});
		} else if (e.keyCode === 38) {
			if (activeOption === 0) {
				return;
			}
			this.setState({ activeOption: activeOption - 1 });
		} else if (e.keyCode === 40) {
			if (activeOption === filteredOptions.length - 1) {
				return;
			}
			this.setState({ activeOption: activeOption + 1 });
		}
	};

	render() {
		const {
			onChange,
			onClick,
			onKeyDown,

			state: { filteredOptions, showOptions, userInput },
		} = this;
		let optionList;
		if (showOptions && userInput) {
			if (filteredOptions.length) {
				optionList = (
					<div>
						<ul className='options'>
							{filteredOptions.map((optionName, index) => {
								let className;
								return (
									<li
										className={className}
										key={index}
										onMouseDown={(e) => {
											e.preventDefault();
										}}
										onClick={(e) => {
											// console.log('clicked');
											onClick(e);
										}}>
										{optionName}
									</li>
								);
							})}
						</ul>
					</div>
				);
			}
		}
		return (
			<div>
				<div className='search'>
					<Input
						id={this.props.id}
						type='text'
						placeholder='Search the company name'
						aria-label={this.props.ariaLabel}
						className='search-box'
						onChange={onChange}
						onKeyDown={onKeyDown}
						name={this.props.name}
						value={userInput}
						invalid={this.props.invalid}
						autoComplete='new-password'
						maxLength={2048}
						onBlur={(e) => {
							// console.log('blurred');
							this.setState({ showOptions: false });
						}}
					/>
				</div>
				{optionList}
			</div>
		);
	}
}

export default Autocomplete;
