import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import {
	Button,
	Form,
	FormGroup,
	Container,
	Col,
	Label,
	Input,
	FormFeedback,
	InputGroup,
	InputGroupAddon
} from 'reactstrap';
import * as Yup from 'yup';
import zipcodes from 'zipcodes';
import { states } from '../constants/states';
import { Loading } from '../utils/utils';
import { getHttpMethods } from '../utils/httpMethods';
import { enAPI } from '../config';
// import Creatable from 'react-select/creatable';
import CompanyError from '../utils/CompanyError';
import { isEmpty } from 'lodash';
import Autocomplete from './AutoComplete';

function PersonalDetails(props) {
	const defaultFormState = {
		firstName: '',
		lastName: '',
		companyName: '',
		customCompanyName: '',
		address: '',
		state: '',
		city: '',
		zip: '',
		mobile_number: '',
		country: '',
		other: false,
		phone_number: ''
	};
	const [loading, setLoading] = useState(false);
	const [errorCompanyNames, setError] = useState(false);
	const [companyNames, setCompanyNames] = useState([]);
	const [customCompany, setCustomCompany] = useState(undefined);
	useEffect(() => {
		if (props.validEmailForm) {
			setLoading(true);
			const http = getHttpMethods();
			http
				.get(enAPI + `DS/companylist`)
				.then(res => {
					setLoading(false);
					setCompanyNames(res.data);
				})
				.catch(err => {
					setLoading(false);
					setError(err);
				});
		}
	}, [props.validEmailForm]);

	const optionList = list => {
		return list.map((opt, index) => {
			return (
				<option value={opt.value} key={index}>
					{opt.label}
				</option>
			);
		});
	};

	return (
		<React.Fragment>
			<Formik
				initialValues={defaultFormState}
				validationSchema={Yup.object().shape({
					firstName: Yup.string()
						.required('First Name is required')
						.test('validateFirstName', 'Invalid First Name', firstName => {
							let regex = new RegExp('[<>{}]');
							return isEmpty(String(firstName).trim()) || regex.test(firstName) ? false : true;
						}),
					lastName: Yup.string()
						.required('Last Name is required')
						.test('validateLastName', 'Invalid Last Name', lastName => {
							let regex = new RegExp('[<>{}]');
							return isEmpty(String(lastName).trim()) || regex.test(lastName) ? false : true;
						}),
					companyName: Yup.string()
						.ensure()
						.when('other', {
							is: false,
							then: Yup.string().required('Company name required')
						})
						.test('validateCompany', 'Invalid Company', company => {
							let regex = new RegExp('[<>{}]');
							return regex.test(company) ? false : true;
						}),
					phone_number: Yup.string()
						.matches(/^[0-9]+$/, 'Must be only digits')
						.min(10, 'Must be exactly 10 digits')
						.max(10, 'Must be exactly 10 digits'),
					address: Yup.string()
						.required('Address is required')
						.test('validateAddress', 'Invalid Address', address => {
							let regex = new RegExp('[<>{}]');
							return isEmpty(String(address).trim()) || regex.test(address) ? false : true;
						}),
					state: Yup.string().required('State is required'),
					zip: Yup.string()
						.required('zip is required')
						.test('validateZip', 'Enter Valid Zip code', zip => {
							return /^[0-9]{5}(?:-[0-9]{4})?$/.test(zip) ? true : false;
						}),
					city: Yup.string()
						.required('City is required')
						.test('validateCity', 'Invalid City', city => {
							let regex = new RegExp('[<>{}]');
							return isEmpty(String(city).trim()) || regex.test(city) ? false : true;
						}),
					mobile_number: Yup.string()
						.required('Mobile number required')
						.matches(/^[0-9]+$/, 'Must be only digits')
						.min(10, 'Must be exactly 10 digits')
						.max(10, 'Must be exactly 10 digits')
				})}
				onSubmit={values => {
					values.mobile_number = '+1' + values.mobile_number;
					values.phone_number = values.phone_number && '+1' + values.phone_number;
					values.country = 'United States of America';
					values['customCompanyName'] = customCompany;
					props.setValidForm(true);
					props.setFormData({ ...props.formData, ...values });
				}}
			>
				{({ handleSubmit, values, handleBlur, touched, errors, setFieldValue }) => (
					<Container
						style={{
							border: '1.5px solid #ddd',
							padding: '1% 5%',
							minHeight: '70vh'
						}}
					>
						{loading && <Loading />}
						{errorCompanyNames && (
							<div className='required' style={{ fontSize: '1rem' }}>
								System Error occured while fetching companyNames
							</div>
						)}
						<Form onSubmit={handleSubmit}>
							<FormGroup row>
								<Col sm='6'>
									<Label for='firstName'>
										First Name:<span className='required'>*</span>
										{'  '}
									</Label>
									<Input
										id='firstName'
										type='text'
										name='firstName'
										maxLength={30}
										aria-label='firstName'
										tag={Field}
										onBlur={handleBlur}
										invalid={Boolean(errors.firstName && touched.firstName)}
										onChange={event => {
											setFieldValue('firstName', event.target.value);
										}}
									/>
									<FormFeedback style={{ fontSize: '0.8rem' }}>{errors.firstName}</FormFeedback>
								</Col>
								<Col sm='6'>
									<Label for='lastName'>
										Last Name:<span className='required'>*</span>
										{'  '}
									</Label>
									<Input
										id='lastName'
										type='text'
										name='lastName'
										tag={Field}
										maxLength={30}
										aria-label='lastName'
										onBlur={handleBlur}
										invalid={Boolean(errors.lastName && touched.lastName)}
										onChange={event => {
											setFieldValue('lastName', event.target.value);
										}}
									/>
									<FormFeedback style={{ fontSize: '0.8rem' }}>{errors.lastName}</FormFeedback>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Col sm='6'>
									<Label for='companyName'>
										Company Name:<span className='required'>*</span>
										{'  '}
									</Label>
									<Autocomplete
										id='companyName'
										ariaLabel='companyName'
										options={companyNames}
										name={'companyName'}
										setCustomCompany={setCustomCompany}
										setFieldValue={setFieldValue}
										invalid={Boolean(errors.companyName && touched.companyName)}
									/>
									{errors.companyName && <CompanyError message={errors.companyName} touched={touched.companyName} />}
								</Col>
								<Col>
									<Label for='phone_number'>Company Phone Number:</Label>
									<InputGroup>
										<InputGroupAddon addonType='prepend'>+1</InputGroupAddon>
										<Input
											id='phone_number'
											maxLength={10}
											type={'text'}
											name='phone_number'
											aria-label='phone_number'
											tag={Field}
											onChange={event => {
												setFieldValue('phone_number', event.target.value);
											}}
											onBlur={handleBlur}
											value={values.phone_number}
											invalid={Boolean(errors.phone_number && touched.phone_number)}
										/>{' '}
										<FormFeedback style={{ fontSize: '0.8rem' }}>{errors.phone_number}</FormFeedback>
									</InputGroup>
								</Col>{' '}
							</FormGroup>
							<FormGroup row>
								<Col>
									<Label for='address'>
										Address:<span className='required'>*</span>
									</Label>
									<Input
										id='address'
										component={'textarea'}
										name='address'
										maxLength={2048}
										tag={Field}
										aria-label='address'
										onChange={event => {
											setFieldValue('address', event.target.value);
										}}
										onBlur={handleBlur}
										value={values.address}
										invalid={Boolean(errors.address && touched.address)}
									/>
									<FormFeedback style={{ fontSize: '0.8rem' }}>{errors.address}</FormFeedback>
								</Col>
								<Col>
									<Label for='zip'>
										Zip:<span className='required'>*</span>
									</Label>
									<Input
										id='zip'
										type={'text'}
										aria-label='zip'
										name='zip'
										tag={Field}
										onChange={event => {
											setFieldValue('zip', event.target.value);
										}}
										onBlur={event => {
											let validZipcode = zipcodes.lookup(event.target.value);
											if (validZipcode) {
												setFieldValue('state', validZipcode.state);
												setFieldValue('city', validZipcode.city);
											} else {
												setFieldValue('state', '');
												setFieldValue('city', '');
											}
										}}
										value={values.zip}
										invalid={Boolean(errors.zip && touched.zip)}
									/>
									<FormFeedback style={{ fontSize: '0.8rem' }}>{errors.zip}</FormFeedback>
								</Col>{' '}
							</FormGroup>
							<FormGroup row>
								<Col>
									<Label for='state'>
										State:<span className='required'>*</span>
									</Label>
									<Input
										id='state'
										component={'select'}
										name='state'
										aria-label='state'
										tag={Field}
										onChange={event => {
											setFieldValue('state', event.target.value);
										}}
										onBlur={handleBlur}
										value={values.state}
										invalid={Boolean(errors.state && touched.state)}
									>
										{optionList(states)}
									</Input>
									<FormFeedback style={{ fontSize: '0.8rem' }}>{errors.state}</FormFeedback>
								</Col>
								<Col>
									<Label for='city'>
										City:<span className='required'>*</span>
									</Label>
									<Input
										id='city'
										type={'text'}
										name='city'
										maxLength={30}
										aria-label='city'
										tag={Field}
										onChange={event => {
											setFieldValue('city', event.target.value);
										}}
										onBlur={handleBlur}
										value={values.city}
										invalid={Boolean(errors.city && touched.city)}
									/>
									<FormFeedback style={{ fontSize: '0.8rem' }}>{errors.city}</FormFeedback>
								</Col>{' '}
							</FormGroup>
							<FormGroup row>
								<Col>
									<Label for='country'>
										Country:<span className='required'>*</span>
									</Label>
									<Input
										id='country'
										type='text'
										name='country'
										aria-label='country'
										tag={Field}
										value={'United States of America'}
										disabled
									/>
								</Col>
								<Col>
									<Label for='mobile_number'>
										Mobile Number:<span className='required'>*</span>
									</Label>
									<InputGroup>
										<InputGroupAddon addonType='prepend'>+1</InputGroupAddon>
										<Input
											id='mobile_number'
											maxLength={10}
											type={'text'}
											name='mobile_number'
											aria-label='mobile_number'
											tag={Field}
											onChange={event => {
												setFieldValue('mobile_number', event.target.value);
											}}
											onBlur={handleBlur}
											value={values.mobile_number}
											invalid={Boolean(errors.mobile_number && touched.mobile_number)}
										/>{' '}
										<FormFeedback style={{ fontSize: '0.8rem' }}>{errors.mobile_number}</FormFeedback>
									</InputGroup>
								</Col>{' '}
							</FormGroup>
							<FormGroup row>
								<Col className='text-center text-md-right'>
									<Button type='submit' disabled={errorCompanyNames}>
										Next Step
									</Button>
								</Col>
							</FormGroup>
						</Form>
					</Container>
				)}
			</Formik>
		</React.Fragment>
	);
}

export default PersonalDetails;
