import Axios from 'axios';

export const getHttpMethods = () => {
	// Axios.defaults.headers.common['x-api-key'] =
	// 	'GnbxWITe4xaEC7r5lGqwE2dlddWu62DOaifZLA9h';
	Axios.defaults.headers.common['Pragma'] = 'no-cache';
	var http = {};
	http.get = function (uri, input) {
		return Axios.get(uri, input);
	};

	http.post = function (uri, input) {
		return new Promise(function (resolve, reject) {
			try {
				Axios.post(uri, input)
					.then((response) => {
						resolve(response);
					})
					.catch((error) => {
						// console.log(error);
						reject(error);
					});
			} catch (error) {
				reject(error);
			}
		});
	};

	http.delete = function (uri, input) {
		return new Promise(function (resolve, reject) {
			try {
				Axios.delete(uri, { params: input })
					.then((response) => {
						resolve(response);
					})
					.catch((error) => {
						// console.log(error);
						reject(error);
					});
			} catch (error) {
				reject(error);
			}
		});
	};

	http.all = function (iterable) {
		return Axios.all(iterable);
	};

	http.spread = function (callback) {
		return Axios.spread(callback);
	};

	return http;
};
