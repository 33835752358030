import React, { useState } from 'react';
import { Container } from 'reactstrap';
import Header from './Headers';
import EmailForm from './EmailForm';
import DistributorDetails from './Distributor';
import PersonalDetails from './PersonalDetails';
import DocumentTitle from 'react-document-title';
import Footer from './Footer';

function App() {
	const [validEmailForm, setValidEmailForm] = useState(false);
	const [validPersonalForm, setValidPersonalForm] = useState(false);
	const [validDistributorForm, setValidDistributorForm] = useState(false);
	const [formData, setFormData] = useState({});
	return (
		<DocumentTitle title='Dealer Source Registration'>
			<Container fluid className='wrapper'>
				<Header flag={validEmailForm} />
				<Container fluid className='containerHeight'>
					{!validEmailForm && (
						<EmailForm
							setValidForm={setValidEmailForm}
							setFormData={setFormData}
							formData={formData}
						/>
					)}
					{validEmailForm && !validPersonalForm && (
						<PersonalDetails
							setValidForm={setValidPersonalForm}
							setFormData={setFormData}
							formData={formData}
							validEmailForm={validEmailForm}
						/>
					)}
					{validPersonalForm && validEmailForm && !validDistributorForm && (
						<DistributorDetails
							setFormData={setFormData}
							formData={formData}
							setValidForm={setValidDistributorForm}
						/>
					)}
				</Container>
				<Footer />
			</Container>
		</DocumentTitle>
	);
}
export default App;
