import React from 'react';
import { Field, getIn } from 'formik';
import { Input, Spinner } from 'reactstrap';

export const customInput = ({
	field,
	form: { touched, errors, setFieldValue, handleChange },
	...props
}) => (
	<div>
		<Input
			className={field.className}
			placeholder={field.placeholder}
			bsSize={field.bsSize}
			invalid={!!(touched[field.name] && errors[field.name])}
			onChange={props.onChange}
			readOnly={field.readOnly}
			maxLength={field.maxLength}
			style={field.style}
			{...field}
			{...props}
		/>
		<ErrorMessage name={field.name} />
	</div>
);

export const ErrorMessage = ({ name, width = null }) => (
	<Field name={name}>
		{({ form }) => {
			const error = getIn(form.errors, name);
			const touch = getIn(form.touched, name);
			return touch && !!error ? (
				<div style={{ color: '#b50202', marginTop: '.5rem', width: width }}>
					{error}
				</div>
			) : null;
		}}
	</Field>
);

export const Loading = () => (
	<Spinner
		style={{
			width: '5rem',
			height: '5rem',
			position: 'fixed',
			zIndex: '999',
			overflow: 'visible',
			margin: 'auto',
			top: '0',
			left: '0',
			bottom: '0',
			right: '0',
		}}
	/>
);

export const CompanyError = ({ message }) => {
	if (message) {
		return <div className='form-message invalid'>{message}</div>;
	}
};

export const generatePoolObject = (formData) => {
	const {
		address,
		city,
		companyName,
		country,
		customCompanyName,
		email,
		firstName,
		lastName,
		state,
		mobile_number,
		phone_number,
		zip,
		sonyDistributor,
		professional,
		consumer,
		fromTDSynnex,
		TDAccount,
		distName,
		distAccount,
	} = { ...formData };
	return {
		address: String(address).trim(),
		custom_city: String(city).trim(),
		custom_company: String(companyName).trim(),
		customCompanyName: String(customCompanyName).trim(),
		given_name: String(firstName).trim(),
		name: String(companyName).trim(),
		family_name: String(lastName).trim(),
		email: String(email).trim(),
		custom_state: state,
		custom_zip: zip,
		phone_number: mobile_number,
		custom_country: country,
		custom_displayname: `${String(lastName).trim()},${String(
			firstName
		).trim()}`,
		custom_telephone: phone_number,
		sonyDistributor,
		professional,
		consumer,
		fromTDSynnex,
		TDAccount: String(TDAccount).trim(),
		distName: String(distName).trim(),
		distAccount: String(distAccount).trim(),
	};
};

export const loadScriptByURL = (id, url, callback) => {
	const isScriptExist = document.getElementById(id);

	if (!isScriptExist) {
		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = url;
		script.id = id;
		script.onload = function () {
			if (callback) callback();
		};
		document.body.appendChild(script);
	}
	// if (isScriptExist && callback) callback();
};

export const loadScript = (SITE_KEY) => {
	loadScriptByURL(
		'recaptcha-key',
		`https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`
	);
}; 
