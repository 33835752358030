import React, { useState } from 'react';
import '../CSS/Footers.css';
import { PRIVACY_POLICY_LINKS, ContactModal } from '../utils/FooterLinks';
import USFLAG from '../images/us-flag.png';

function Footer() {
	const [modal, setModal] = useState(false);
	const toggle = () => {
		setModal(!modal);
	};
	return (
		<div className='Footer'>
			{modal && (
				<ContactModal
					modal={modal}
					toggle={() => {
						toggle();
					}}
				/>
			)}
			<ul className='Footer__links'>
				{PRIVACY_POLICY_LINKS.map((linkObj, index) => (
					<li className='Footer__link' key={index}>
						<a
							href={linkObj['url']}
							target='_blank'
							rel='noopener noreferrer'
							style={{
								fontWeight: 'bold',
								color: '#ccc',
								fontSize: '0.8rem',
							}}
							aria-label={linkObj['label']}>
							{linkObj['label']} |
						</a>
					</li>
				))}
				<li className='Footer__link' key={'contactus'}>
					<a
						href='true'
						style={{
							fontWeight: 'bold',
							color: '#ccc',
							fontSize: '0.8rem',
							paddingTop: '7.8px',
							cursor: 'pointer',
						}}
						onClick={(e) => {
							e.preventDefault();
							toggle();
						}}>
						Contact Us |
					</a>
				</li>
				<li className='USFlagLogo' key={'flag'}>
					<img src={USFLAG} alt={'USFLAG'} />
				</li>
			</ul>
		</div>
	);
}

export default Footer;
