import 'react-app-polyfill/ie11';
//import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import DealerSourceReg from './Components/DealerSourceReg';
import PageNotFound from '../src/Components/PageNotFound';
import { RedirectApp } from '../src/Components/RedirectApp';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Switch>
				<Route exact path='/' component={RedirectApp} />
				<Route exact path='/DS' component={DealerSourceReg} />
				<Route component={PageNotFound} />
			</Switch>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);
