import React from 'react';
import Logo from './Logo';
const Headers = (props) => (
	<React.Fragment>
		<Logo />
		{props.flag && (
			<div style={{ marginTop: '8px' }}>
				<h5 style={{ fontWeight: 'bold', textAlign: 'center' }}>
					Registration Form
				</h5>
			</div>
		)}
	</React.Fragment>
);

export default Headers;
