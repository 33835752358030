import React from 'react';

const CompanyError = ({ touched, message }) => {
	if (!touched) {
		return <div className='form-message invalid'>&nbsp;</div>;
	}
	if (message) {
		return (
			<div className='form-message invalid' style={{ fontSize: '0.8rem' }}>
				{message}
			</div>
		);
	}
	return <div className='form-message valid'></div>;
};

export default CompanyError;
