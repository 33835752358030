import React from 'react';
import { Button, Modal, Alert, ModalBody } from 'reactstrap';

export const ContactModal = props => {
	return (
		<Modal isOpen={props.modal} toggle={props.toggle}>
			<ModalBody>
				<Alert
					color='info'
					style={{
						width: '100%',
						textAlign: 'center'
					}}
				>
					<p>
						For registration issues,{' '}
						<a href={`mailto:GlobalServiceDesk.US@am.sony.com`} style={{ color: 'blue' }}>
							email
						</a>{' '}
						or call 877-652-7669 (USA). Include the website (Dealer Source), your name, email address,company name and a
						description of the issue.
					</p>
				</Alert>
				<div style={{ float: 'right' }}>
					<Button color='secondary' onClick={props.toggle}>
						Close
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export const PRIVACY_POLICY_LINKS = [
	{
		key: 'SEL_T_C',
		url: 'https://www.sony.com/electronics/terms-conditions',
		label: 'SEL Terms and Conditions '
	},
	{
		key: 'SEL_PRIVACY_POLICY',
		url: 'https://electronics.sony.com/privacy-policy',
		label: 'SEL Privacy Policy '
	},
	{
		key: 'CA_PRIVACY_NOTICE',
		url: 'https://electronics.sony.com/privacy-policy#DataPractices',
		label: 'CA Privacy Notice '
	}
];

// module.exports = { PRIVACY_POLICY_LINKS };
