import React from 'react';
import SonyLogo from '../images/sony-logo-vector.png';
import '../CSS/Logo.css';
import { NavbarBrand } from 'reactstrap';
import { DS_APP_LOGO_URL } from '../config';
import { AiFillHome } from 'react-icons/ai';

const Logo = () => (
	<div style={{ backgroundColor: 'black' }}>
		<div className='Logo' style={{ marginLeft: '2%', marginTop: '-1%' }}>
			<div>
				<NavbarBrand href={DS_APP_LOGO_URL}>
					<div className='Logo__typo'>
						<img className='Logo__Sony' src={SonyLogo} alt='Sony Logo' />
					</div>
				</NavbarBrand>
				<NavbarBrand href='/' style={{ color: 'white', fontSize: '25px' }}>
					<AiFillHome style={{ marginTop: '-6px' }} />
					<span style={{ opacity: '0.0' }}>-</span>
				</NavbarBrand>
			</div>
			<h5 className='text-dark floatRight' style={{ marginRight: '2%' }}>
				<a href={DS_APP_LOGO_URL}>
					<span style={{ color: 'white' }}>DEALER</span>{' '}
					<b style={{ color: '#5390d2' }}>SOURCE.</b>
				</a>
			</h5>
		</div>
	</div>
);

export default Logo;
